import { GridOptions } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { ObservationViewModel } from '@hl7fhir/resource-types';
import { BehaviorSubject, Observable } from 'rxjs';

export type ShareModel = ObservationViewModel;

@Injectable({
  providedIn: 'root',
})
export class ShareMeasurementsService {
  private readonly _data: BehaviorSubject<ObservationViewModel[]> = new BehaviorSubject<ShareModel[]>([]);
  private readonly _gridOptions: BehaviorSubject<GridOptions<any>> = new BehaviorSubject<GridOptions>({});
  private readonly accountReference: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(
    undefined,
  );

  /**
   * The initial data that is used to filter the data.
   * It will never change till the next setRowData call.
   */
  private initialData: ShareModel[] = [];

  displayName = '';

  /**
   * The observable that emits the current data. This data is displayed in the preview grid and will either be
   * downloaded as a JSON file or sent to the health provider.
   */
  get data$(): Observable<ShareModel[]> {
    return this._data.asObservable();
  }

  /**
   * The observable that emits the current grid options.
   * GridOptions are the options that are used to configure the preview grid.
   */
  get gridOptions$(): Observable<GridOptions> {
    return this._gridOptions.asObservable();
  }

  /**
   * The observable that emits the current account reference.
   * AccountReference is the reference of the provider that the data will be shared with.
   */
  get accountReference$(): Observable<string | undefined> {
    return this.accountReference.asObservable();
  }

  /**
   * Sets the  data.
   *
   * @param data - An array of SharedModel objects representing the  data.
   */
  setRowData(rowData: ShareModel[]): void {
    this.initialData = rowData;

    this._data.next(rowData);
  }

  /**
   * Sets the grid options.
   *
   * @param gridOptions - The grid options to set.
   */
  setGridOptions(gridOptions: GridOptions): void {
    this._gridOptions.next(gridOptions);
  }

  /**
   * Sets the account reference.
   * @param accountReference - The account reference to set.
   */
  setAccountReference(accountReference: string | undefined): void {
    this.accountReference.next(accountReference);
  }

  /**
   * Filters the data using the provided predicate.
   *
   * @param predicate - The predicate to use for filtering the data.
   */
  filterData(predicate: (value: ShareModel) => boolean): void {
    this._data.next(this.initialData.filter(predicate));
  }

  setDisplayName(displayName: string): void {
    this.displayName = displayName;
  }
}
